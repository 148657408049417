import "core-js/modules/es6.regexp.search";
import _toConsumableArray from "/opt/valet/valet-admin/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// fuse is a lightweight fuzzy-search module
// make search results more in line with expectations
import Fuse from 'fuse.js';
import path from 'path';
import i18n from '@/lang';
export default {
  name: 'HeaderSearch',
  data: function data() {
    return {
      search: '',
      options: [],
      searchPool: [],
      show: false,
      fuse: undefined
    };
  },
  computed: {
    routes: function routes() {
      return this.$store.getters.permission_routes;
    },
    lang: function lang() {
      return this.$store.getters.language;
    }
  },
  watch: {
    lang: function lang() {
      this.searchPool = this.generateRoutes(this.routes);
    },
    routes: function routes() {
      this.searchPool = this.generateRoutes(this.routes);
    },
    searchPool: function searchPool(list) {
      this.initFuse(list);
    },
    show: function show(value) {
      if (value) {
        document.body.addEventListener('click', this.close);
      } else {
        document.body.removeEventListener('click', this.close);
      }
    }
  },
  mounted: function mounted() {
    this.searchPool = this.generateRoutes(this.routes);
  },
  methods: {
    click: function click() {
      this.show = !this.show;

      if (this.show) {
        this.$refs.headerSearchSelect && this.$refs.headerSearchSelect.focus();
      }
    },
    close: function close() {
      this.$refs.headerSearchSelect && this.$refs.headerSearchSelect.blur();
      this.options = [];
      this.show = false;
    },
    change: function change(val) {
      var _this = this;

      this.$router.push(val.path);
      this.search = '';
      this.options = [];
      this.$nextTick(function () {
        _this.show = false;
      });
    },
    initFuse: function initFuse(list) {
      this.fuse = new Fuse(list, {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [{
          name: 'title',
          weight: 0.7
        }, {
          name: 'path',
          weight: 0.3
        }]
      });
    },
    // Filter out the routes that can be displayed in the sidebar
    // And generate the internationalized title
    generateRoutes: function generateRoutes(routes) {
      var basePath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/';
      var prefixTitle = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      var res = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = routes[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var router = _step.value;

          // skip hidden router
          if (router.hidden) {
            continue;
          }

          var data = {
            path: path.resolve(basePath, router.path),
            title: _toConsumableArray(prefixTitle)
          };

          if (router.meta && router.meta.title) {
            // generate internationalized title
            var i18ntitle = i18n.t("route.".concat(router.meta.title));
            data.title = [].concat(_toConsumableArray(data.title), [i18ntitle]);

            if (router.redirect !== 'noRedirect') {
              // only push the routes with title
              // special case: need to exclude parent router without redirect
              res.push(data);
            }
          } // recursive child routes


          if (router.children) {
            var tempRoutes = this.generateRoutes(router.children, data.path, data.title);

            if (tempRoutes.length >= 1) {
              res = [].concat(_toConsumableArray(res), _toConsumableArray(tempRoutes));
            }
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return res;
    },
    querySearch: function querySearch(query) {
      if (query !== '') {
        this.options = this.fuse.search(query);
      } else {
        this.options = [];
      }
    }
  }
};